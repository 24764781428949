import { Component, Inject, inject } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaintenanceService } from '../../../../../services/maintenance.service';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'app-delete-maintenance',
	standalone: true,
	imports: [MatDialogModule, MatButton],
	templateUrl: './delete-maintenance.component.html',
	styleUrl: './delete-maintenance.component.scss',
})
export class DeleteMaintenanceComponent {
	readonly dialogRef = inject(MatDialogRef<DeleteMaintenanceComponent>);

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: string,
		public readonly maintenanceService: MaintenanceService,
		private snackBar: MatSnackBar,
	) {
		console.log(data);
	}

	onConfirm() {
		this.maintenanceService.deleteMaintenance(this.data).subscribe({
			next: () => {
				this.snackBar.open('Delete was successful!', 'OK', {
					duration: 0,
					panelClass: ['green-primary'],
					horizontalPosition: 'end',
				});

				this.dialogRef.close('saved');
			},
		});
	}
}
