/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { UsersService } from '../users.service';
import { UserModel } from '../../models/Users/UserModel';
import { catchError, map, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export const CanActivate = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const userService = inject(UsersService);
	const router = inject(Router);
	return userService.getUserInfo().pipe(
		map((user: UserModel | null) => user !== null && user.isAdmin === true),
		catchError((err: HttpErrorResponse) => {
			let errorKey: string | null = 'DEFAULT';
			if (err.headers.keys().indexOf('error-code') > -1) {
				errorKey = err.headers.get('error-code');
			}
			router.navigate(['/error', errorKey]);
			return of(false);
		}),
	);
};

export const CanActivateChild = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => CanActivate(route, state);
