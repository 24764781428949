import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserModel } from '../models/Users/UserModel';
import { VendorContactInfo } from '../models/Users/VendorContactInfo';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	constructor(private http: HttpClient) {}

	userInfo: ReplaySubject<UserModel | null> =
		new ReplaySubject<UserModel | null>();

	_userInfo: UserModel | null = null;

	getUserInfo(): Observable<UserModel | null> {
		const user = this.queryUserInfo();
		user.subscribe((x) => {
			this.userInfo.next(x);
			this._userInfo = x;
		});
		return this.userInfo;
	}

	queryUserInfo(): Observable<UserModel | null> {
		return this.http
			.get<UserModel>(`${environment.apiEndpoint}/User/GetUserInfo`)
			.pipe(map((data: any) => UserModel.adapt(data)));
	}

	setAdminVendor(vendorNum: string) {
		if (this._userInfo == null) return;
		this._userInfo.Vendor = VendorContactInfo.adapt({
			VendorNum: vendorNum,
		});
	}
}
