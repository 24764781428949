export class VendorReportModel {
	public NewLeadTime: number | null = null;
	public Comment: string | null = null;
	constructor(
		public ItemNumber: string,
		public ModelNum: string | null,
		public Manufacturer: string | null,
		public LastLeadTime: number | null,
		public ItemShortDesc: string | null,
		public NeedsUpdate: boolean | null,
	) {}

	static adapt(item: any): VendorReportModel | null {
		if (!item) return null;
		const ret = new VendorReportModel(
			item.ItemNumber,
			item.ModelNum,
			item.Manufacturer,
			item.LastLeadTime,
			item.ItemShortDesc,
			item.NeedsUpdate,
		);
		// ret.NewLeadTime = ret.LastLeadTime;

		return ret;
	}
}
