import { Component } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss',
})
export class ElectronFooterComponent {
	footerUrls = [
		{
			url: 'https://www.duke-energy.com/legal/privacy',
			title: 'Privacy',
		},
		{
			url: 'https://www.duke-energy.com/legal/ccpa',
			title: 'California Consumer Privacy Statement',
		},
		{
			url: 'https://www.duke-energy.com/legal/ccpa-opt-out-request-form',
			title: 'Do Not Sell or Share My Personal Information (CA)',
		},
		{
			url: 'https://www.duke-energy.com/legal/terms-of-use',
			title: 'Terms of Use',
		},
		{
			url: 'https://www.duke-energy.com/customer-service/accessibility',
			title: 'Accessibility',
		},
	];
}
