<div class="table-container">
    <div class="table-holder">
        <h2 *ngIf="title !== null" id="table-title">{{ title }}</h2>
        <ng-content select="[filters]"></ng-content>
        <div class="table-scroller">
            <table
                mat-table
                [dataSource]="getPageData()"
                aria-describedby="table-title"
            >
                <ng-container [matColumnDef]="'rowNum'">
                    <ng-container>
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            {{
                                1 +
                                    i +
                                    this.paginator.pageIndex *
                                        this.paginator.pageSize
                            }}
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngFor="let col of getColumnDefs()"
                    [matColumnDef]="col.column"
                >
                    <ng-container *ngIf="col.template === null">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ col.title }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                col.displayType === null
                                    ? element[col.column]
                                    : formatValue(col, element)
                            }}
                        </td>
                    </ng-container>
                    <ng-container *ngIf="col.template !== null">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            [class]="col.cellClass"
                        >
                            {{ col.title }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [class]="col.cellClass"
                        >
                            <ng-container
                                *ngTemplateOutlet="
                                    col.template;
                                    context: { $implicit: element }
                                "
                            ></ng-container>
                        </td>
                    </ng-container>
                </ng-container>
                <tr
                    mat-header-row
                    *matHeaderRowDef="getVisibleColumnNames()"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: getVisibleColumnNames()"
                ></tr>
            </table>
        </div>
        <mat-paginator
            [length]="getFilteredData().length"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageSizeOptions"
            aria-label="Select page"
            (page)="handlePageEvent($event)"
            #paginator
        ></mat-paginator>
    </div>
    <electron-loader [visible]="loading"></electron-loader>
</div>
