import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { UsersService } from './users.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AdminVendorInterceptor implements HttpInterceptor {
	constructor(private userService: UsersService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (this.userService._userInfo?.isAdmin) {
			if (this.userService._userInfo.Vendor?.VendorNumber) {
				return next.handle(
					req.clone({
						headers: req.headers.set(
							'X-ADMIN-VENDOR',
							this.userService._userInfo.Vendor?.VendorNumber,
						),
					}),
				);
			}
		} else if (this.userService._userInfo?.multipleVendors) {
			if (this.userService._userInfo.Vendor?.VendorNumber) {
				return next.handle(
					req.clone({
						headers: req.headers.set(
							'X-VENDOR',
							this.userService._userInfo.Vendor?.VendorNumber,
						),
					}),
				);
			}
		}
		return next.handle(req);
	}
}
