import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from '../app.component';
import { VendorReportService } from '../services/vendor-report.service';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatModule } from './mat.module';
import { ElectronComponentsModule } from './electron.components.module';
import { ComponentsModule } from './components.module';
import { MaintenanceService } from '../services/maintenance.service';
import {
	MSALGuardConfigFactory,
	MSALInstanceFactory,
	MSALInterceptorConfigFactory,
	appConfig,
} from '../app.config';
import {
	MsalInterceptor,
	MSAL_INSTANCE,
	MSAL_GUARD_CONFIG,
	MSAL_INTERCEPTOR_CONFIG,
	MsalService,
	MsalGuard,
	MsalBroadcastService,
} from '@azure/msal-angular';
import { UsersService } from '../services/users.service';
import { AdminVendorInterceptor } from '../services/admin-vendor.interceptor';
import { ElectronFileUploadComponent } from '../components/electron/file-upload/file-upload.component';
import { ElectronLoaderComponent } from '../components/electron/loader/loader.component';
import { ElectronAccordionComponent } from '../components/electron/accordion/accordion.component';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		FormsModule,
		CommonModule,
		RouterModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatModule,
		ElectronComponentsModule,
		ComponentsModule,
		ElectronFileUploadComponent,
		ElectronLoaderComponent,
		ElectronAccordionComponent,
	],
	providers: [
		VendorReportService,
		UsersService,
		MaintenanceService,
		{
			provide: 'AppConfig',
			useValue: appConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AdminVendorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
