import { Component } from '@angular/core';
import { MaintenanceService } from '../../services/maintenance.service';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-maintenance-page',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './maintenance.component.html',
	styleUrl: './maintenance.component.scss',
})
export class MaintenancePageComponent {
	constructor(private readonly maintService: MaintenanceService) {}
	isMaintenance = () => this.maintService.isInMaintenance();

	getMaintenanceMessage(): string {
		const currentMaint = this.maintService.currentMaintenance()!;
		return currentMaint.getFormattedMessage();
	}
	getMaintenanceTitle(): string {
		const currentMaint = this.maintService.currentMaintenance()!;
		return currentMaint.getTitle();
	}
}
