<h2 mat-dialog-title>
    {{ form.controls.ID.value === "" ? "Add" : "Edit" }} Maintenance Window
</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" class="dialog-form">
        <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput type="text" [formControl]="form.controls.Title" />
            <mat-error>{{ checkForErrorsIn(form.controls.Title) }}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Message</mat-label>
            <textarea
                matInput
                type="text"
                [formControl]="form.controls.Message"
            ></textarea>
            <mat-error>{{ checkForErrorsIn(form.controls.Message) }}</mat-error>
        </mat-form-field>
        <div class="form-row">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    [formControl]="form.controls.MaintenanceStartDate"
                    [matDatepicker]="startdp"
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="startdp"
                ></mat-datepicker-toggle>
                <mat-datepicker #startdp></mat-datepicker>
                <mat-error>{{
                    checkForErrorsIn(form.controls.MaintenanceStartDate)
                }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Start Time</mat-label>
                <input
                    matInput
                    [formControl]="form.controls.MaintenanceStartTime"
                />
                <mat-hint>h:mm aa</mat-hint>
                <mat-error>{{
                    checkForErrorsIn(form.controls.MaintenanceStartTime)
                }}</mat-error>
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input
                    matInput
                    [formControl]="form.controls.MaintenanceEndDate"
                    [matDatepicker]="enddp"
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="enddp"
                ></mat-datepicker-toggle>
                <mat-datepicker #enddp></mat-datepicker>
                <mat-error>{{
                    checkForErrorsIn(form.controls.MaintenanceEndDate)
                }}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>End Time</mat-label>
                <input
                    matInput
                    [formControl]="form.controls.MaintenanceEndTime"
                />
                <mat-hint>h:mm aa</mat-hint>
                <mat-error>{{
                    checkForErrorsIn(form.controls.MaintenanceEndTime)
                }}</mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="onConfirm()" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
