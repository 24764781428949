import { NgModule } from '@angular/core';
import { ElectronFooterComponent } from '../components/electron/footer/footer.component';
import { ElectronHeaderComponent } from '../components/electron/header/header.component';
import { ElectronTableComponent } from '../components/electron/table/table.component';
import { MatModule } from './mat.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ElectronLoaderComponent } from '../components/electron/loader/loader.component';
import { ElectronAccordionComponent } from '../components/electron/accordion/accordion.component';

@NgModule({
	declarations: [
		ElectronHeaderComponent,
		ElectronFooterComponent,
		ElectronTableComponent,
	],
	imports: [
		MatModule,
		CommonModule,
		RouterModule,
		ElectronLoaderComponent,
		ElectronAccordionComponent,
	],
	providers: [],
	bootstrap: [],
	exports: [
		ElectronHeaderComponent,
		ElectronFooterComponent,
		ElectronTableComponent,
		ElectronAccordionComponent,
	],
})
export class ElectronComponentsModule {}
