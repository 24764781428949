import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'app-icon',
	template: `<span #svgContainer style="display: flex"><svg></svg></span>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [],
	styleUrl: './icon-component.component.scss',
})
export class IconComponent implements OnChanges {
	@Input() src?: string;
	@ViewChild('svgContainer', { static: true }) svgContainerRef!: ElementRef;
	@HostBinding('style.display') display = 'inline-flex';

	constructor(private http: HttpClient) {}

	ngOnChanges() {
		if (!this.src) return;
		this.http.get(this.src, { responseType: 'text' }).subscribe((data) => {
			const div = this.svgContainerRef.nativeElement;
			if (div) {
				div.innerHTML = data;
			}
		});
	}
}
