import {
	Component,
	EventEmitter,
	Input,
	Output,
	TemplateRef,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';

@Component({
	selector: 'electron-table',
	templateUrl: './table.component.html',
	styleUrl: './table.component.scss',
})
class ElectronTableComponent {
	@Input()
	dataSource: any[] = [];
	@Input()
	columns: Column[] = [];
	@Input()
	title: string | null = null;
	@Input()
	filterFunction: (itemData: any) => boolean = () => true;
	@Output()
	filteredData = new EventEmitter<any[]>();

	@Input()
	loading: boolean = false;

	@Input()
	ShowRowNumber: boolean = false;

	private _getVisibleColumns(): Column[] {
		return this.columns.filter((x) => x.visible);
	}

	getVisibleColumnNames(): string[] {
		const arr = this._getVisibleColumns().map((x) => x.column);
		if (this.ShowRowNumber) {
			arr.unshift('rowNum');
		}
		return arr;
	}
	getColumnDefs(): Column[] {
		return this._getVisibleColumns();
	}
	getFilteredData(): any[] {
		const data = this.dataSource.filter(this.filterFunction);
		this.filteredData.emit(data);
		return data;
	}
	getPageData(): any[] {
		return this.getFilteredData().slice(
			this.pageIndex * this.pageSize,
			this.pageIndex * this.pageSize + this.pageSize,
		);
	}

	formatValue(col: Column, element: any) {
		if (col.displayType === 'date') {
			return moment(element[col.column]).format('M/D/YYYY h:mm A');
		}
		return element;
	}

	pageSize: number = 10;
	pageIndex: number = 0;
	pageSizeOptions: number[] = [10, 25, 50];
	handlePageEvent(e: PageEvent) {
		this.pageIndex = e.pageIndex;
		this.pageSize = e.pageSize;
	}
}

class Column {
	title: string | null = null;
	visible: boolean = true;
	column: string = null!;
	template: TemplateRef<any> | null = null;
	cellClass: string | null = null;
	displayType: string | null = null;

	constructor(
		column: string,
		title: string | null = null,
		visible: boolean = true,
		template: TemplateRef<any> | null = null,
		cellClass: string | null = null,
	) {
		this.column = column;
		this.visible = visible;
		this.title = title;
		this.template = template;
		this.cellClass = cellClass;
	}
}

export { ElectronTableComponent, Column };
