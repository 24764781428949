import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { HeaderLink } from './components/electron/header/header.component';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventType, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	private destroyRef = inject(DestroyRef);
	isNavigating: boolean = false;
	links: HeaderLink[] = [
		<HeaderLink>{
			link: '/log-in',
			title: 'Log In',
			icon: 'login',
			primary: true,
		},
	];
	loggedInLinks: HeaderLink[] = [
		<HeaderLink>{
			link: '/vendor-report',
			title: 'Vendor Report',
			icon: 'summarize',
			primary: false,
		},
		<HeaderLink>{
			click: () => {
				this.authService.instance.logoutRedirect();
			},
			title: 'Logout',
			icon: 'logout',
			primary: true,
		},
	];

	constructor(
		private readonly authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private router: Router,
	) {
		this.router.events
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((e) => this.navigationInterceptor(e));
	}

	ngOnInit(): void {
		this.authService.instance.enableAccountStorageEvents();
		this.authService.instance.initialize();
		this.msalBroadcastService.msalSubject$
			.pipe(
				// Optional filtering of events.
				// filter(
				// 	(msg: EventMessage) =>
				// 		msg.eventType === EventType.LOGIN_SUCCESS,
				// ),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(() => {
				if (this.authService.instance.getAllAccounts().length > 0) {
					this.links = this.loggedInLinks;
				}
				// Do something with the result
			});
		// }
	}

	private navigationInterceptor(event: any): void {
		if (event.type === EventType.NavigationStart) {
			this.isNavigating = true;
		}

		if (event.type === EventType.GuardsCheckEnd) {
			if (
				event.urlAfterRedirects === '/vendor-report' &&
				event.shouldActivate === false
			) {
				this.router.navigate(['error/NO_VENDOR']);
			}
		}
		if (
			event.type === EventType.NavigationEnd ||
			event.type === EventType.NavigationCancel ||
			event.type === EventType.NavigationError
		) {
			this.isNavigating = false;
		}
	}
}
