/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { MaintenanceService } from '../maintenance.service';

export const CanActivate = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const router = inject(Router);
	const maintService = inject(MaintenanceService);
	if (maintService.isInMaintenance()) {
		router.navigate(['/maintenance']);
		return false;
	}
	return true;
};

export const CanActivateChild = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => CanActivate(route, state);
