import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-not-found',
	standalone: true,
	imports: [],
	template: '',
})
export class NotFoundComponent {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) {
		router.navigate(['/error/NOT_FOUND'], {
			queryParams: {
				lastUrl: router.url,
			},
		});
	}
}
