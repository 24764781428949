import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon-component/icon-component.component';

@Component({
	selector: 'electron-accordion',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './accordion.component.html',
})
export class ElectronAccordionComponent {
	@Input()
	title: string = '';

	@Input()
	expanded: boolean = false;
}
