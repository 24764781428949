import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorReportComponent } from './pages/vendor-report/vendor-report.component';
import * as MaintenanceAuthGuard from './services/AuthGuards/maintenance.guard';
import * as VendorAuthGuard from './services/AuthGuards/vendor.guard';
import * as AdminAuthGuard from './services/AuthGuards/admin.guard';
import { MaintenancePageComponent } from './pages/maintenance/maintenance.component';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorComponent } from './pages/error/error.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { LoggedOutComponent } from './pages/logged-out/logged-out.component';
import { LogInComponent } from './pages/log-in/log-in.component';
import { AdminMaintenanceComponent } from './pages/admin/maintenance/maintenance.component';

export const routes: Routes = [
	{
		path: 'maintenance',
		component: MaintenancePageComponent,
		data: {
			title: 'Maintenance',
		},
		canActivate: [MsalGuard],
	},
	{
		path: 'error/:errorCode',
		component: ErrorComponent,
		data: {
			title: 'Error',
		},
		canActivate: [MsalGuard],
	},
	{
		path: 'logged-out',
		component: LoggedOutComponent,
		data: {
			title: 'Logged Out',
		},
		pathMatch: 'full',
	},
	{
		path: 'admin',
		children: [
			{
				path: 'maintenance',
				component: AdminMaintenanceComponent,
				data: {
					title: 'Maintenance',
				},
				pathMatch: 'full',
			},
		],
		canActivateChild: [AdminAuthGuard.CanActivateChild, MsalGuard],
	},
	{
		path: '',
		children: [
			{
				path: '',
				redirectTo: 'vendor-report',
				pathMatch: 'full',
			},
			{
				path: 'log-in',
				component: LogInComponent,
				data: {
					title: 'Log In',
				},
			},
			{
				path: 'vendor-report',
				component: VendorReportComponent,
				data: {
					title: 'Supplier Lead Time Portal',
				},
				canActivate: [],
			},
		],
		canActivateChild: [
			MaintenanceAuthGuard.CanActivateChild,
			VendorAuthGuard.CanActivateChild,
			MsalGuard,
		],
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
