import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ViewChild,
	WritableSignal,
	inject,
	signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VendorReportService } from '../../../services/vendor-report.service';
import { ElectronFileUploadComponent } from '../../../components/electron/file-upload/file-upload.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorReportImportModel } from '../../../models/VendorReport/VendorReportImportModel';
import { HttpErrorResponse } from '@angular/common/http';
import { ElectronLoaderComponent } from '../../../components/electron/loader/loader.component';

@Component({
	selector: 'app-vendor-report-import',
	templateUrl: 'report-import.component.html',
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		ElectronFileUploadComponent,
		ElectronLoaderComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportImportDialogComponent {
	readonly dialogRef = inject(MatDialogRef<ReportImportDialogComponent>);
	loading: WritableSignal<boolean> = signal(false);
	loadProgress: WritableSignal<number> = signal(0);

	@ViewChild('fileUpload', { static: true })
	fileUpload!: ElectronFileUploadComponent;

	errors: WritableSignal<string[]> = signal([]);

	constructor(
		private readonly vendorReport: VendorReportService,
		private snackBar: MatSnackBar,
	) {}

	onConfirm = () => {
		if (this.fileUpload.file != null) {
			this.loading.set(true);
			this.vendorReport
				.importVendorInput(this.fileUpload.file)
				.subscribe({
					next: (data: any) => this.handleSubscribe(data),
					error: (data: HttpErrorResponse) => {
						this.errors.set([data.message]);
						this.reset();
						this.snackBar.open('Error with upload.', 'OK', {
							duration: 0,
							panelClass: ['orange-secondary'],
							horizontalPosition: 'end',
						});
					},
				});
		}
	};

	handleSubscribe = (data: any) => {
		this.loadProgress.set((100 / 4) * data.type);
		if (data.type === 4) {
			this.loading.set(false);
			const model = VendorReportImportModel.adapt(data.body)!;
			if (model.status === true) {
				this.snackBar.open('Upload was successful!', 'OK', {
					duration: 0,
					panelClass: ['green-primary'],
					horizontalPosition: 'end',
				});
				this.reset();
				this.dialogRef.close('saved');
			} else {
				this.snackBar.open('Error with upload.', 'OK', {
					duration: 0,
					panelClass: ['orange-secondary'],
					horizontalPosition: 'end',
				});
				this.reset();
				this.errors.set(model.messages);
			}
		}
	};

	reset = () => {
		this.loading.set(false);
		this.loadProgress.set(0);
	};
}
