import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'electron-file-upload',
	standalone: true,
	imports: [MatIconModule, MatButtonModule],
	templateUrl: './file-upload.component.html',
	styleUrl: './file-upload.component.scss',
})
export class ElectronFileUploadComponent {
	@Input()
	requiredFileType: string | null = null;

	public file: File | null = null;
	fileName: string | null = null;

	onFileSelected(event: Event) {
		if (event == null || event.target == null) return;
		const target = event.target as HTMLInputElement;
		this.file = (target.files as FileList)[0];

		if (this.file) {
			this.fileName = this.file.name;
		} else {
			this.fileName = null;
		}
	}
}
