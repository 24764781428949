import { VendorContactInfo } from './VendorContactInfo';

class UserModel {
	constructor(
		public Email: string,
		public Name: string | null = null,
		public Vendor: VendorContactInfo | null = null,
		public isAdmin: boolean,
		public multipleVendors: boolean,
	) {}

	static adapt(item: any): UserModel | null {
		if (!item) return null;
		const ret = new UserModel(
			item.Email,
			item.Name,
			VendorContactInfo.adapt(item.Vendor),
			item.isAdmin,
			item.multipleVendors,
		);
		return ret;
	}
}

export { UserModel };
