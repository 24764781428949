import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'app-error-page',
	standalone: true,
	imports: [],
	templateUrl: './error.component.html',
	styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
	title: string = 'An Error Occurred';
	message: string = 'Please contact your administrator for more information.';

	constructor(private route: ActivatedRoute) {}
	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			if (params['errorCode']) {
				switch (params['errorCode']) {
					case 'NO_VENDOR': {
						this.message =
							'Thank you for your interest in visiting Duke Energy’s Supplier Lead Time website. Currently, the email address you are using to access our site is not linked to our system. To add your email to our system, please contact your Duke Energy Sourcing or Procurement Representative.';
						break;
					}
					case 'NOT_FOUND': {
						this.title = 'Route not found';
						if (params['lastUrl'] !== null) {
							this.message = `The route "${params['lastUrl']}" did not exist.`;
						} else {
							this.message = 'The route did not exist.';
						}
						break;
					}
				}
			}
		});
	}
}
