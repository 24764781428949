<h2 mat-dialog-title>Import Excel</h2>
<mat-dialog-content class="mat-typography">
    <p>
        You will need to import the same excel spreadsheet that you exported in
        "Export to Excel". This data will be validated when uploaded. If there
        are any errors, they will be listed here.
    </p>
    <div *ngIf="errors().length > 0" class="dialog-error-messages">
        <p *ngFor="let error of errors()">{{ error }}</p>
    </div>
    <electron-file-upload
        #fileUpload
        requiredFileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ></electron-file-upload>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="onConfirm()" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
<electron-loader [visible]="loading()"></electron-loader>
