import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-log-in',
	standalone: true,
	imports: [],
	template: '',
})
export class LogInComponent {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) {
		router.navigate(['/vendor-report']);
	}
}
