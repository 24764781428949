export class VendorContactInfo {
	constructor(
		public VendorNumber: string,
		public VendorName: string,
	) {}

	static adapt(item: any): VendorContactInfo | null {
		if (!item) return null;
		const ret = new VendorContactInfo(
			item.VendorNum,
			item.VendorName?.trim(),
		);
		return ret;
	}
}
