import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Column } from '../../../components/electron/table/table.component';
import {
	MaintenanceService,
	MaintenanceWindow,
} from '../../../services/maintenance.service';
import { MatDialog } from '@angular/material/dialog';
import { EditMaintenanceComponent } from './dialogs/edit-maintenance/edit-maintenance.component';
import { DeleteMaintenanceComponent } from './dialogs/delete-maintenance/delete-maintenance.component';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrl: './maintenance.component.scss',
})
export class AdminMaintenanceComponent implements OnInit {
	@ViewChild('buttonTemplate', { static: true })
	buttonTemplate!: TemplateRef<void>;

	reportColumns: Column[] = [];
	loading: boolean = false;
	items: MaintenanceWindow[] = [];

	constructor(
		private readonly maintenanceService: MaintenanceService,
		private readonly dialog: MatDialog,
	) {}

	ngOnInit(): void {
		const MaintStartCol = new Column('MaintenanceStart', 'Start');
		MaintStartCol.displayType = 'date';
		const MaintEndCol = new Column('MaintenanceEnd', 'End');
		MaintEndCol.displayType = 'date';
		this.reportColumns = [
			new Column('Title', 'Title'),
			new Column('Message', 'Message'),
			MaintStartCol,
			MaintEndCol,
			new Column(
				'ButtonCol',
				'',
				true,
				this.buttonTemplate,
				'button-col',
			),
		];

		this.loadData();
	}

	loadData() {
		this.loading = true;
		this.maintenanceService
			.getMaintenances()
			.subscribe((data: MaintenanceWindow[]) => {
				this.items = data;
				this.loading = false;
			});
	}

	editMaintenance(element: MaintenanceWindow): void {
		const dialogRef = this.dialog.open(EditMaintenanceComponent, {
			data: element,
		});
		dialogRef.afterClosed().subscribe((data: string) => {
			if (data === 'saved') {
				this.loadData();
			}
		});
	}
	newMaintenance(): void {
		this.editMaintenance(new MaintenanceWindow());
	}
	deleteMaintenance(element: MaintenanceWindow): void {
		const dialogRef = this.dialog.open(DeleteMaintenanceComponent, {
			data: element.ID,
		});
		dialogRef.afterClosed().subscribe((data) => {
			if (data === 'saved') {
				this.loadData();
			}
		});
	}
}
