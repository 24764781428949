import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	NavigationEnd,
	Router,
} from '@angular/router';
import { filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
})
class ElectronHeaderComponent implements OnInit {
	showMenu = false;
	@Input()
	links: HeaderLink[] = [];
	pageTitle: string = '';
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}
	ngOnInit(): void {
		this.router.events
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute.snapshot),
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
			)
			.subscribe((route: ActivatedRouteSnapshot) => {
				this.pageTitle = route.data['title'] || '';
			});
	}
}

class HeaderLink {
	public link: string | null = null;
	public click: (() => boolean) | undefined = undefined;
	public title!: string;
	public icon!: string;
	public primary: boolean = false;
}

export { ElectronHeaderComponent, HeaderLink };
