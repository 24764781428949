import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [],
	imports: [
		MatButtonModule,
		MatTableModule,
		MatPaginatorModule,
		MatIconModule,
		MatFormField,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		NgxMatSelectSearchModule,
		MatFormFieldModule,
		ReactiveFormsModule,
	],
	providers: [],
	bootstrap: [],
	exports: [
		MatButtonModule,
		MatTableModule,
		MatPaginatorModule,
		MatIconModule,
		MatFormField,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		NgxMatSelectSearchModule,
		MatFormFieldModule,
		ReactiveFormsModule,
	],
})
export class MatModule {}
