import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ElectronFileUploadComponent } from '../../../components/electron/file-upload/file-upload.component';
import { VendorReportService } from '../../../services/vendor-report.service';
import { ElectronLoaderComponent } from '../../../components/electron/loader/loader.component';

@Component({
	selector: 'app-vendor-report-export',
	templateUrl: 'report-export.component.html',
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		ElectronFileUploadComponent,
		ElectronLoaderComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportExportDialogComponent {
	readonly dialogRef = inject(MatDialogRef<ReportExportDialogComponent>);
	loading: boolean = false;

	constructor(private readonly vendorReport: VendorReportService) {}

	onConfirm(): void {
		this.loading = true;
		this.vendorReport.export().subscribe((value: Blob) => {
			const a = document.createElement('a');
			a.href = URL.createObjectURL(value);
			a.download = 'DataExport.xlsx';
			// start download
			a.click();
			this.dialogRef.close();
			this.loading = false;
		});
	}
}
