import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	ChangeDetectionStrategy,
	OnInit,
	inject,
	Inject,
	signal,
	WritableSignal,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
	MatDialogModule,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VendorInputInsertModel } from '../../../models/VendorReport/VendorInputInsertModel';
import { VendorReportImportModel } from '../../../models/VendorReport/VendorReportImportModel';
import { VendorReportService } from '../../../services/vendor-report.service';
import { ElectronLoaderComponent } from '../../../components/electron/loader/loader.component';

@Component({
	selector: 'app-vendor-report-submit',
	templateUrl: 'report-submit.component.html',
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatButton,
		ElectronLoaderComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSubmitDialogComponent implements OnInit {
	readonly dialogRef = inject(MatDialogRef<ReportSubmitDialogComponent>);
	loading: WritableSignal<boolean> = signal(true);
	errors: WritableSignal<string[]> = signal([]);

	constructor(
		private readonly vendorReport: VendorReportService,
		@Inject(MAT_DIALOG_DATA)
		public data: { items: VendorInputInsertModel[] },
		private snackBar: MatSnackBar,
	) {}
	ngOnInit(): void {
		this.vendorReport.saveVendorInput(this.data.items).subscribe({
			next: (data: VendorReportImportModel | null) => {
				if (data === null) {
					this.snackBar.open(
						'Update failed. No error output.',
						'OK',
						{
							duration: 0,
							panelClass: ['orange-secondary'],
							horizontalPosition: 'end',
						},
					);
					this.loading.set(false);
					return;
				}
				if (data.status === true) {
					this.snackBar.open('Update was successful!', 'OK', {
						duration: 0,
						panelClass: ['green-primary'],
						horizontalPosition: 'end',
					});
					this.dialogRef.close('saved');
				} else {
					this.errors.set(data.messages);
					this.snackBar.open('Error with upload.', 'OK', {
						duration: 0,
						panelClass: ['orange-secondary'],
						horizontalPosition: 'end',
					});
					this.loading.set(false);
				}
			},
			error: (data: HttpErrorResponse) => {
				this.snackBar.open('Error with upload.', 'OK', {
					duration: 0,
					panelClass: ['orange-secondary'],
					horizontalPosition: 'end',
				});
				this.errors.set([data.message]);
				this.loading.set(false);
			},
		});
	}
}
