import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from '@angular/material/dialog';
import {
	MaintenanceService,
	MaintenanceWindow,
} from '../../../../../services/maintenance.service';
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatError,
	MatFormField,
	MatHint,
	MatLabel,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import moment from 'moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-edit-maintenance',
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		MatButton,
		ReactiveFormsModule,
		MatLabel,
		MatFormField,
		MatInputModule,
		MatDatepickerModule,
		MatHint,
		MatError,
	],
	providers: [
		provideNativeDateAdapter(),
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { subscriptSizing: 'dynamic' },
		},
	],
	templateUrl: './edit-maintenance.component.html',
	styleUrl: './edit-maintenance.component.scss',
})
export class EditMaintenanceComponent {
	readonly dialogRef = inject(MatDialogRef<EditMaintenanceComponent>);
	form = new FormGroup(
		{
			ID: new FormControl<string>(this.data.ID ?? ''),
			Title: new FormControl<string>(this.data.Title, [
				Validators.required,
			]),
			Message: new FormControl<string>(this.data.Message, [
				Validators.required,
			]),
			MaintenanceStartDate: new FormControl<Date | null>(
				this.data.MaintenanceStart,
				[
					Validators.required,
					momentValidator(
						'm/d/yyyy',
						'Invalid date. Expecting a date in format m/d/yyyy',
					),
				],
			),
			MaintenanceStartTime: new FormControl<string>(
				moment(this.data.MaintenanceStart).isValid()
					? moment(this.data.MaintenanceStart).format('h:mm a')
					: '',
				[
					Validators.required,
					momentValidator(
						'h:mm a',
						'Invalid time. Expecting a time in format h:mm aa',
					),
				],
			),
			MaintenanceEndDate: new FormControl<Date | null>(
				this.data.MaintenanceEnd,
				[
					Validators.required,
					momentValidator(
						'm/d/yyyy',
						'Invalid date. Expecting a date in format m/d/yyyy',
					),
				],
			),
			MaintenanceEndTime: new FormControl<string>(
				moment(this.data.MaintenanceEnd).isValid()
					? moment(this.data.MaintenanceEnd).format('h:mm a')
					: '',
				[
					Validators.required,
					momentValidator(
						'h:mm a',
						'Invalid time. Expecting a time in format h:mm aa',
					),
				],
			),
		},
		[this.DTB4DTValidator()],
	);

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: MaintenanceWindow,
		public readonly maintenanceService: MaintenanceService,
		private snackBar: MatSnackBar,
	) {
		console.log(data);
	}

	createDate(
		d: FormControl<Date | null>,
		t: FormControl<string | null>,
	): moment.Moment | null {
		if (d == null || t == null) return null;
		const Output = moment(d.value);
		const Time = moment(t.value, 'h:mm a');

		Output.set({
			hour: Time.get('hour'),
			minute: Time.get('minute'),
		});

		return Output;
	}

	onConfirm() {
		if (this.form.valid) {
			const S = this.createDate(
				this.form.controls.MaintenanceStartDate!,
				this.form.controls.MaintenanceStartTime!,
			);
			const E = this.createDate(
				this.form.controls.MaintenanceEndDate!,
				this.form.controls.MaintenanceEndTime!,
			);

			const model = MaintenanceWindow.adapt({
				ID: this.data.ID,
				Title: this.form.value.Title,
				Message: this.form.value.Message,
				MaintenanceStart: S,
				MaintenanceEnd: E,
			});

			this.maintenanceService.updateMaintenance(model!).subscribe({
				next: (data: MaintenanceWindow | null) => {
					if (data === null) {
						this.snackBar.open(
							'Update failed. No error output.',
							'OK',
							{
								duration: 0,
								panelClass: ['orange-secondary'],
								horizontalPosition: 'end',
							},
						);
						return;
					}
					this.snackBar.open('Update was successful!', 'OK', {
						duration: 0,
						panelClass: ['green-primary'],
						horizontalPosition: 'end',
					});

					this.dialogRef.close('saved');
				},
			});
		} else {
			this.form.markAllAsTouched();
		}
	}

	checkForErrorsIn(formControl: AbstractControl): string {
		if (formControl.hasError('required')) {
			return 'This field is required';
		}

		if (formControl.hasError('invalidDate')) {
			return formControl.getError('invalidDate');
		}
		return '';
	}

	DTB4DTValidator(): ValidatorFn {
		return (): ValidationErrors | null => {
			if (!this.form || !this.form.controls) return null;
			const D1 = this.form.controls.MaintenanceStartDate!;
			const T1 = this.form.controls.MaintenanceStartTime!;
			const D2 = this.form.controls.MaintenanceEndDate!;
			const T2 = this.form.controls.MaintenanceEndTime!;

			if (D1 != null && T1 && D2 && T2) {
				const S = this.createDate(D1, T1);
				const E = this.createDate(D2, T2);
				if (S && E && S.isSameOrAfter(E)) {
					D1.setErrors({
						invalidDate:
							'Start Date/Time is larger than End Date/Time',
					});
				} else if (D1.hasError('invalidDate') && D1.errors != null) {
					delete D1.errors['invalidDate'];
				}
			}
			return null;
		};
	}
}

function momentValidator(format: string, message: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value == null || control.value === '') return null;
		const Output = moment(control.value, format, true);

		if (!Output.isValid()) {
			return {
				invalidDate: message,
			};
		}
		return null;
	};
}
