export class VendorReportImportModel {
	constructor(
		public status: boolean,
		public messages: string[] = [],
		public data: any = {},
	) {}

	static adapt(item: any): VendorReportImportModel | null {
		if (!item) return null;
		const ret = new VendorReportImportModel(
			item.status,
			item.messages,
			item.data,
		);

		return ret;
	}
}
