import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app-routing.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
	MsalInterceptorConfiguration,
	MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
	LogLevel,
	IPublicClientApplication,
	PublicClientApplication,
	BrowserCacheLocation,
	InteractionType,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';
export function loggerCallback() {
	// logLevel: LogLevel, message: string
	// console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msalConfig.auth.clientId,
			authority: environment.msalConfig.auth.authority,
			redirectUri: '/',
			postLogoutRedirectUri: '/logged-out',
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
		},
		system: {
			allowNativeBroker: false, // Disables WAM Broker
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(
		environment.apiConfig.uri,
		environment.apiConfig.scopes,
	);
	protectedResourceMap.set(
		environment.apiEndpoint,
		environment.apiConfig.appScopes,
	);
	protectedResourceMap.set(
		environment.maintenanceEndpoint,
		environment.apiConfig.appScopes,
	);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: [...environment.apiConfig.scopes],
		},
		loginFailedRoute: '/login-failed',
	};
}

export const appConfig: ApplicationConfig = {
	providers: [provideRouter(routes), provideAnimationsAsync('noop')],
};
