<h2 mat-dialog-title>Export Excel</h2>
<mat-dialog-content class="mat-typography">
    <p>
        Do you want to export this report as an excel spreadsheet? This
        spreadsheet will be locked except for the specific column that will need
        to be entered. All green cells will need to be filled.
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="onConfirm()" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
<electron-loader [visible]="loading"></electron-loader>
