<input
    type="file"
    class="file-input"
    [accept]="requiredFileType"
    (change)="onFileSelected($event)"
    #fileUpload
/>

<div class="file-upload">
    <button
        mat-mini-fab
        color="primary"
        class="upload-btn"
        (click)="fileUpload.click()"
    >
        <mat-icon>attach_file</mat-icon>
    </button>
    {{ fileName || "No file uploaded yet." }}
</div>
