import { NgModule } from '@angular/core';
import { VendorReportComponent } from '../pages/vendor-report/vendor-report.component';
import { VendorReportService } from '../services/vendor-report.service';
import { MatModule } from './mat.module';
import { ElectronComponentsModule } from './electron.components.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaintenanceComponent } from '../components/maintenance/maintenance.component';
import { AdminMaintenanceComponent } from '../pages/admin/maintenance/maintenance.component';
import { IconComponent } from '../components/electron/icon-component/icon-component.component';

@NgModule({
	declarations: [
		VendorReportComponent,
		MaintenanceComponent,
		AdminMaintenanceComponent,
	],
	imports: [
		MatModule,
		ElectronComponentsModule,
		CommonModule,
		FormsModule,
		IconComponent,
	],
	providers: [VendorReportService],
	bootstrap: [],
	exports: [VendorReportComponent, MaintenanceComponent],
})
export class ComponentsModule {}
