<h2 mat-dialog-title>Uploading Data</h2>
<mat-dialog-content class="mat-typography">
    <p>Lead Time Data Submission.</p>
    <div *ngIf="errors().length > 0" class="dialog-error-messages">
        <p *ngFor="let error of errors()">{{ error }}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
<electron-loader [visible]="loading()"></electron-loader>
