export const environment = {
	// apiEndpoint: 'https://localhost:55312/api',
	apiEndpoint: '@API_ENDPOINT@/api',
	maintenanceEndpoint: '@API_ENDPOINT@/maintenance',
	msalConfig: {
		auth: {
			clientId: '@AZURE_CLIENTID@',
			authority: '@AZURE_AUTHORITY@',
		},
	},
	apiConfig: {
		uri: 'https://graph.microsoft.com',
		scopes: ['User.Read'],
		appScopes: ['api://@AZURE_CLIENTID@/User.Read'],
	},
};
