<ng-template #buttonTemplate let-element>
    <button mat-flat-button (click)="editMaintenance(element)">
        Edit
        <mat-icon iconPositionEnd>edit</mat-icon>
    </button>
    <button mat-flat-button (click)="deleteMaintenance(element)">
        Delete
        <mat-icon iconPositionEnd>delete</mat-icon>
    </button>
</ng-template>
<electron-table
    [dataSource]="items"
    [columns]="reportColumns"
    title="Maintenance Periods"
    [loading]="loading"
    [ShowRowNumber]="false"
>
    <div class="filter-bar" filters>
        <div class="left"></div>
        <div class="right">
            <button mat-flat-button (click)="newMaintenance()">
                Create New Maintenance Window
                <mat-icon iconPositionEnd>create</mat-icon>
            </button>
        </div>
    </div>
</electron-table>
