<div class="accordion-box">
    <button
        (click)="expanded = !expanded"
        [ariaExpanded]="expanded ? 'true' : 'false'"
        class="flex items-center justify-between w-full p-16 md:py-24 text-xl text-teal-darker text-left hover:bg-teal-lighter transition-colors duration-200"
        type="button"
        aria-controls="accordion-panel-3"
        id="accordion-trigger-3"
        aria-expanded="false"
    >
        {{ title }}
        <app-icon
            src="/icons/base/caret-down-base.svg"
            class="icon-16 md:icon-20 flex-shrink-0 ml-24 md:ml-32 transform duration-300"
        ></app-icon>
    </button>
</div>
<div
    x-ref="answer"
    [ariaHidden]="expanded ? 'false' : 'true'"
    [class]="expanded ? 'grid-rows-1fr visible' : 'grid-rows-0fr invisible'"
    class="grid relative px-16 pt-8 transition-all duration-300"
    id="accordion-panel-3"
    aria-labelledby="accordion-trigger-3"
    aria-hidden="true"
>
    <div
        [class]="
            expanded ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-12'
        "
        class="overflow-hidden transform transition-all duration-500"
    >
        <div class="rich-text max-w-xl pb-24">
            <ng-content></ng-content>
        </div>
    </div>
</div>
