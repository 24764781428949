<header role="banner">
    <!--
            Logo
            If logo or logo/title is centered
              - Add class "justify-center"
            If left aligned
              - Add class "justify-between"
            Note: Logo or logo/title may only be centered
            when there is no hamburger or utility nav.
          -->

    <div class="container">
        <div class="left">
            <!--
                HAMBURGER / WAFFLE
              -->

            <div class="menu" *ngIf="showMenu">
                <button type="button" mat-icon-button color="primary">
                    <mat-icon fontIcon="menu" class="icon-2x"></mat-icon>
                </button>
            </div>

            <!--
                LOGO
                By default we use the DE logo. You can easily switch out
                the logo that uses a sub-brand, such as the DEP logo.
              -->

            <a [routerLink]="''" aria-label="Duke Energy homepage">
                <img src="assets/images/logo.svg" alt="logo" class="logo" />
            </a>

            <!--
                SECTION TITLE / APP TITLE / IDENTIFIER
              -->
            <div class="title">
                <span> {{ pageTitle }} </span>
            </div>
        </div>

        <!--
              UTILITY NAVIGATION
            -->
        <nav aria-label="Utility" class="right">
            <ul class="m-0">
                <li *ngFor="let link of links">
                    <a
                        *ngIf="link.link"
                        [routerLink]="link.link"
                        target="_self"
                        mat-flat-button
                        [color]="link.primary ? 'primary' : 'secondary'"
                    >
                        <mat-icon [fontIcon]="link.icon"></mat-icon>
                        <span> {{ link.title }} </span>
                    </a>
                    <button
                        *ngIf="link.click"
                        mat-flat-button
                        [color]="link.primary ? 'primary' : 'secondary'"
                        type="button"
                        (click)="link.click()"
                    >
                        <mat-icon [fontIcon]="link.icon"></mat-icon>
                        <span> {{ link.title }} </span>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</header>
