import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subscription, interval, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
class MaintenanceService implements OnDestroy {
	refresh: Subscription;
	constructor(
		private http: HttpClient,
		private router: Router,
	) {
		const refreshInterval: number = 10; //minutes
		const source = interval(1000 * 60 * refreshInterval);
		this.refresh = source.subscribe(() => this.updateMaintenanceStatus());
		this.updateMaintenanceStatus();
	}

	ngOnDestroy() {
		this.refresh.unsubscribe();
	}

	public updatingStatus: boolean = false;
	private maintenances: MaintenanceWindow[] = [];
	private maintenanceRoute: string = '/maintenance';
	private lastRoute: string = '';
	private setMaintenances = (data: any) => {
		this.maintenances = <MaintenanceWindow[]>(
			data.map(MaintenanceWindow.adapt).filter((x: any) => x !== null)
		);
	};

	getMaintenances(): Observable<MaintenanceWindow[]> {
		return this.http
			.get<MaintenanceWindow[]>(environment.maintenanceEndpoint)
			.pipe(
				map(
					(data: any[]) =>
						<MaintenanceWindow[]>(
							data
								.map(MaintenanceWindow.adapt)
								.filter((x) => x !== null)
						),
				),
			);
	}

	updateMaintenance(
		item: MaintenanceWindow,
	): Observable<MaintenanceWindow | null> {
		return this.http
			.put(`${environment.maintenanceEndpoint}`, JSON.stringify(item), {
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
				},
			})
			.pipe(map(MaintenanceWindow.adapt));
	}

	deleteMaintenance(ID: string): Observable<any> {
		return this.http.delete(`${environment.maintenanceEndpoint}${ID}`, {
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/json',
			},
		});
	}

	updateMaintenanceStatus() {
		this.updatingStatus = true;
		const data = this.getMaintenances();

		data.subscribe((data: MaintenanceWindow[]) => {
			this.updatingStatus = false;
			this.maintenances = data;
			if (
				this.isInMaintenance() &&
				this.router.url != this.maintenanceRoute
			) {
				this.lastRoute = this.router.url;
				this.router.navigate([this.maintenanceRoute]);
			} else if (
				!this.isInMaintenance() &&
				this.router.url == this.maintenanceRoute
			) {
				this.router.navigate([
					this.lastRoute == this.maintenanceRoute
						? ''
						: this.lastRoute,
				]);
				setInterval(() => {
					window.location.reload(); //refresh the page for cache clearing
				}, 100);
			}
		});

		return data;
	}

	currentMaintenance(): MaintenanceWindow | null {
		const curr = this.maintenances.find(
			(x) =>
				x.MaintenanceStart! <= new Date() &&
				(x.MaintenanceEnd == null || x.MaintenanceEnd >= new Date()),
		);
		if (curr === undefined) {
			return null;
		}
		return curr;
	}
	maintenanceWarningDistance: number = 7; //Days
	upcomingMaintenance(): MaintenanceWindow | null {
		const curr = this.maintenances.find(
			(x) =>
				x.MaintenanceStart! >= new Date() &&
				x.MaintenanceStart! <=
					new Date(
						new Date().getTime() +
							this.maintenanceWarningDistance *
								24 *
								60 *
								60 *
								1000,
					),
		);

		if (curr === undefined) {
			return null;
		}
		return curr;
	}

	isInMaintenance(): boolean {
		return this.currentMaintenance() != null;
	}
	isUpcomingMaintenance(): boolean {
		return this.upcomingMaintenance() != null;
	}
}

class MaintenanceWindow {
	public ID: string | null = null;
	public MaintenanceStart: Date | null = null;
	public MaintenanceEnd: Date | null = null;
	public Message!: string;
	public Title!: string;

	static adapt(item: any): MaintenanceWindow | null {
		if (!item) return null;
		const ret = new MaintenanceWindow();
		ret.MaintenanceStart = new Date(item.MaintenanceStart);
		ret.MaintenanceEnd =
			item.MaintenanceEnd == null ? null : new Date(item.MaintenanceEnd);
		ret.Message = item.Message;
		ret.Title = item.Title;
		ret.ID = item.ID;
		return ret;
	}

	private dateFormat: string = 'MMMM Do YYYY, h:mm a';

	getFormattedMessage(): string {
		let message = this.Message;
		message = message?.replace(
			'{start}',
			moment(this.MaintenanceStart).format(this.dateFormat),
		);
		if (this.MaintenanceEnd != null) {
			message = message?.replace(
				'{end}',
				moment(this.MaintenanceEnd).format(this.dateFormat),
			);
		}
		return message;
	}
	getTitle(): string {
		return this.Title ?? 'Upcoming Maintenance';
	}
}

export { MaintenanceWindow, MaintenanceService };
