import { Component } from '@angular/core';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrl: './maintenance.component.scss',
})
export class MaintenanceComponent {
	constructor(private readonly maintService: MaintenanceService) {}

	isUpcomingMaintenance = () => this.maintService.isUpcomingMaintenance();

	getMaintenanceMessage(): string {
		const currentMaint = this.maintService.upcomingMaintenance()!;
		return currentMaint.getFormattedMessage();
	}
	getMaintenanceTitle(): string {
		const currentMaint = this.maintService.upcomingMaintenance()!;
		return currentMaint.getTitle();
	}
}
