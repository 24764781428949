<div class="loading-overlay" [class]="!visible ? 'hide' : 'show'">
    <svg
        class="w-48 h-48 text-blue"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        fill="currentColor"
        aria-hidden="true"
        focusable="false"
    >
        <defs>
            <linearGradient
                id="gradient"
                x1="17.664"
                y1="30.336"
                x2="28.545"
                y2="19.455"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stop-color="#000"></stop>
                <stop offset="1" stop-color="#fff"></stop>
            </linearGradient>
            <mask
                id="mask"
                x="0"
                y="0"
                width="64"
                height="32"
                maskUnits="userSpaceOnUse"
            >
                <rect x="0" width="64" height="32" fill="url(#gradient)"></rect>
            </mask>
        </defs>
        <g mask="url(#mask)">
            <path
                d="m60.483,32c2.093,0,3.743-1.813,3.492-3.877C62.049,12.278,48.468,0,32,0,15.533,0,1.952,12.279.025,28.123c-.251,2.064,1.399,3.877,3.492,3.877h0c1.766,0,3.259-1.306,3.472-3.048,1.513-12.387,12.133-21.985,25.011-21.985,12.877,0,23.498,9.597,25.011,21.985.213,1.742,1.706,3.048,3.472,3.048h0Z"
                opacity=".9"
            ></path>
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 32 32"
                to="360 32 32"
                dur="1.2s"
                repeatCount="indefinite"
            ></animateTransform>
        </g>
    </svg>
</div>
